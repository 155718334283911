import { addDays, addMonths, format } from 'date-fns';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { BiPlusCircle } from 'react-icons/bi';
import { InputDate, InputMoney, InputNumber } from '~/components/NovosInputs';
import useDebounce from '~/hooks/useDebounce';
import { useMediaQuery } from '~/hooks/useMediaQuery';
import { useFocusTabFinalizacao } from '../../hooks/useFocusTabFinalizacao';
import { usePdvOnline } from '../../hooks/usePdvOnline';
import { tabFinalizacaoServices } from '../../services/tab-finalizacao';
import { FinalizadorasTableProps } from '../../types';
import { FinalizadorasPedidoProps } from '../../types/services';
import { InputFinalizadora } from '../InputFinalizadora';
import { FormaPagamentoGrid } from '../TabFinalizacao/components/FormaPagamentoGrid';
import { Totalizadores } from '../Totalizadores';
import {
  ButtonAddFormaPgto,
  FormaPagamentoContainer,
  FormaPagamentoContent,
  SeletorFinalizadora,
  TableContainer,
} from './styles';
import { resetFormaPagamento } from './utils/resetFormaPagamento';
import { toast } from 'react-toastify';
import { formataValorNumerico } from './utils/formataValorNumerico';
import { buttonsFinalizadoras } from './utils/buttonsFinalizadoras';
import api from '~/services/api';
import { moneyFormat } from '~/utils/functions';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal);

export const FormaPagamento: React.FC = () => {
  const {
    isOpenModalFunction,
    finalizacaoContainertRef,
    initialStatePdv,
    handleAddFinalizadoraTable,
    isModalOpen,
    handleAddFinalizadoraPedido,
    resta,
    finalizadorasTable,
    produtosTable,
  } = usePdvOnline();

  const { debouncedFn } = useDebounce();
  const changeLabelQtdParcel = useMediaQuery({ maxWidth: 1800 });

  const [iniciaRequest, setIniciaRequest] = useState(false);
  const [codFinalizadora, setCodFinalizadora] = useState<number | null>(null);
  const [numPrazo, setNumPrazo] = useState(0);
  const [codCondicao, setCodCondicao] = useState(0);
  const [desCondicao, setDesCondicao] = useState('');
  const [fetching, setFetching] = useState(false);
  const [disabledButtonLabel, setDisabledButtonLabel] = useState<string | null>(
    null,
  );
  const [tipoFinalizadora, setTipoFinalizadora] = useState<number | null>(null);

  const { handleInputFocus } = useFocusTabFinalizacao();

  const buttonAddParcelaRef = useRef<HTMLButtonElement | null>(null);

  const {
    register,
    control,
    watch,
    setValue,
    reset,
    resetField,
    getValues,
    formState: { errors },
  } = useForm();

  const desTeclaWatch: string | null = watch('des_tecla');
  const vlrParcelaWatch = watch('vlr_parcela');
  const vencParcWatch = watch('venc_parcela');

  const delay = useCallback((ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }, []);

  const disableAdd =
    isOpenModalFunction ||
    isModalOpen.isOpen ||
    !desTeclaWatch ||
    !vlrParcelaWatch ||
    vlrParcelaWatch === '0,00' ||
    fetching ||
    produtosTable.length === 0;

  useEffect(() => {
    const handleSeletorFinalizadora = () => {
      if (!finalizacaoContainertRef.current || !initialStatePdv) return;

      const seletorFinalizadora =
        finalizacaoContainertRef.current.querySelector(
          '[name="cod_finalizadora"]',
        );

      if (seletorFinalizadora) {
        const { activeElement } = document;

        if (activeElement === seletorFinalizadora) setIniciaRequest(true);
      }
    };

    window.addEventListener('focusin', handleSeletorFinalizadora);
    return () =>
      window.removeEventListener('focusin', handleSeletorFinalizadora);
  }, [finalizacaoContainertRef, initialStatePdv, codFinalizadora]);

  const getTeclaByFinalizadora = useCallback(async () => {
    if (!codFinalizadora || !initialStatePdv) return;

    const desTecla = await tabFinalizacaoServices.getTeclaFinalizadora(
      initialStatePdv.cod_loja,
      codFinalizadora,
    );

    const currentDate = new Date();

    if (desTecla.success) {
      const { des_tecla, cod_condicao, num_condicao, cod_finalizadora } =
        desTecla.message;

      if (!des_tecla) {
        setValue('des_tecla', '');
        setValue('vlr_parcela', '');
        handleInputFocus('des_tecla');
        return toast.warning(
          'Tecla não encontrada, verifique o cadastro de finalizadora',
        );
      }

      setValue('des_tecla', des_tecla);
      handleInputFocus('vlr_pgto');

      const dtaVencParcela = addDays(currentDate, num_condicao);

      setValue('venc_parcela', moment(dtaVencParcela).format('YYYY-MM-DD'));
      setNumPrazo(num_condicao);

      const condicao = await tabFinalizacaoServices.getCondicaoPagamento(
        cod_condicao,
      );

      setDesCondicao(condicao.des_condicao);
      setCodFinalizadora(cod_finalizadora);
      setCodCondicao(cod_finalizadora);
      if (resta > 0) setValue('vlr_parcela', moneyFormat(resta.toFixed(2)));
    }
  }, [codFinalizadora, handleInputFocus, initialStatePdv, resta, setValue]);

  useEffect(() => {
    getTeclaByFinalizadora();
  }, [codFinalizadora]);

  const handleResetFields = (message: string) => {
    setCodFinalizadora(null);
    resetField('cod_finalizadora');
    resetField('des_tecla');
    setValue('qtd_parcelas', 1);
    resetField('venc_parcela');
    setValue('vlr_parcela', '');
    toast.warning(message);
  };

  const handleSuccessfulFetch = async (data: any) => {
    setValue('cod_finalizadora', {
      value: data.cod_finalizadora,
      label: data.des_finalizadora,
    });

    const currentDate = new Date();
    const dtaVencParcela = addDays(currentDate, data.num_condicao);

    setCodFinalizadora(data.cod_finalizadora);
    setValue('venc_parcela', moment(dtaVencParcela).format('YYYY-MM-DD'));
    handleInputFocus('vlr_parcela');
    setNumPrazo(data.num_condicao);

    const condicao = await tabFinalizacaoServices.getCondicaoPagamento(
      data.cod_condicao,
    );

    setDesCondicao(condicao.des_condicao);
    setCodCondicao(data.cod_condicao);
  };

  useEffect(() => {
    if (desTeclaWatch && desTeclaWatch.length <= 3 && initialStatePdv) {
      const fetchData = async () => {
        const { success, data, message } =
          await tabFinalizacaoServices.getFinalizadoraByTecla(
            initialStatePdv.cod_loja,
            desTeclaWatch,
          );

        if (!success || !data) {
          handleResetFields(message);
          return;
        }

        await handleSuccessfulFetch(data);
      };

      debouncedFn(fetchData, 1000);
    }
  }, [desTeclaWatch, handleInputFocus, initialStatePdv, resetField, setValue]);

  const handleFormaPagamentoAdd = useCallback(async () => {
    if (fetching) return;

    if (Number(resta) === 0)
      return toast.warning('O valor a pagar já está completo.');

    if (!desTeclaWatch || !codFinalizadora)
      return handleInputFocus('des_tecla');

    if (!vlrParcelaWatch || vlrParcelaWatch === '0,00')
      return handleInputFocus('vlr_parcela');

    if (!vencParcWatch) return handleInputFocus('venc_parcela');

    const qtdParcelas = Number(getValues('qtd_parcelas')) || 1;
    const parcelasExistentes: FinalizadorasTableProps[] = [
      ...(finalizadorasTable || []),
    ];
    const novasParcelas: FinalizadorasTableProps[] = [];
    const novasParcelasPedido: FinalizadorasPedidoProps[] = [];

    let continuar = true;

    const existeFinalizadoraTipoDinheiro = parcelasExistentes.some(
      (parcela) => parcela.tipo_finalizadora === 0,
    );

    if (existeFinalizadoraTipoDinheiro && tipoFinalizadora === 0) {
      const resultado = await MySwal.fire({
        title: 'Deseja continuar?',
        text: 'Já existe uma parcela como dinheiro lançada.',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        icon: 'warning',
      });
      continuar = resultado.isConfirmed;
    }

    if (!continuar) return;

    const dataVencimento = (numMonths: number) =>
      format(
        addMonths(addDays(new Date(getValues('venc_parcela')), 1), numMonths),
        'dd/MM/yyyy',
      );

    if (qtdParcelas > 1) {
      const { isConfirmed } = await MySwal.fire({
        title: 'Dividir ou replicar?',
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#2B78E4',
        confirmButtonText: 'Dividir',
        cancelButtonText: 'Replicar',
        icon: 'question',
      });

      const gerarParcelas = (valorParcelaCallback: any) => {
        for (let i = 0; i < qtdParcelas; i++) {
          const parcelaId = nanoid();
          const valParcelaAtual = valorParcelaCallback(i);

          const parcela: FinalizadorasTableProps = {
            id: parcelaId,
            num_prazo: numPrazo,
            condicao: desCondicao,
            des_finalizadora: getValues('cod_finalizadora').label,
            dta_vencto: dataVencimento(i),
            vlr_parcela: moneyFormat(valParcelaAtual.toFixed(2)),
            tipo_finalizadora: tipoFinalizadora,
          };

          const parcelaPedido: FinalizadorasPedidoProps = {
            id: parcelaId,
            cod_finalizadora: codFinalizadora,
            cod_condicao: codCondicao,
            num_condicao: numPrazo,
            val_parcela: formataValorNumerico(valParcelaAtual.toFixed(2)),
            dta_vencimento: dataVencimento(i),
          };

          novasParcelas.push(parcela);
          novasParcelasPedido.push(parcelaPedido);
          parcelasExistentes.push(parcela);
        }
      };

      if (isConfirmed) {
        const valorTotal = formataValorNumerico(getValues('vlr_parcela'));
        const valorParcelaAjustada = parseFloat(
          (valorTotal / qtdParcelas).toFixed(2),
        );

        gerarParcelas((i: number) => {
          return i === qtdParcelas - 1
            ? valorTotal - valorParcelaAjustada * (qtdParcelas - 1)
            : valorParcelaAjustada;
        });
      } else {
        gerarParcelas(() => formataValorNumerico(getValues('vlr_parcela')));
      }
    } else {
      const parcelaId = nanoid();

      const parcela: FinalizadorasTableProps = {
        id: parcelaId,
        num_prazo: numPrazo,
        condicao: desCondicao,
        des_finalizadora: getValues('cod_finalizadora').label,
        dta_vencto: dataVencimento(0),
        vlr_parcela: getValues('vlr_parcela'),
        tipo_finalizadora: tipoFinalizadora,
      };

      const parcelaPedido: FinalizadorasPedidoProps = {
        id: parcelaId,
        cod_finalizadora: codFinalizadora,
        cod_condicao: codCondicao,
        num_condicao: numPrazo,
        val_parcela: formataValorNumerico(getValues('vlr_parcela')),
        dta_vencimento: dataVencimento(0),
      };

      novasParcelas.push(parcela);
      novasParcelasPedido.push(parcelaPedido);
      parcelasExistentes.push(parcela);
    }

    handleAddFinalizadoraTable(
      qtdParcelas > 1 ? novasParcelas : novasParcelas[0],
    );
    handleAddFinalizadoraPedido(
      qtdParcelas > 1 ? novasParcelasPedido : novasParcelasPedido[0],
    );

    reset(resetFormaPagamento);
    setCodFinalizadora(null);
    setNumPrazo(0);
    setDesCondicao('');
    setDisabledButtonLabel(null);
  }, [
    codCondicao,
    codFinalizadora,
    desCondicao,
    desTeclaWatch,
    fetching,
    finalizadorasTable,
    getValues,
    handleAddFinalizadoraPedido,
    handleAddFinalizadoraTable,
    handleInputFocus,
    numPrazo,
    reset,
    resta,
    tipoFinalizadora,
    vencParcWatch,
    vlrParcelaWatch,
  ]);

  useEffect(() => {
    const handleKeyEnterPress = (ev: KeyboardEvent) => {
      if (
        finalizacaoContainertRef &&
        ev.key === 'Enter' &&
        buttonAddParcelaRef.current &&
        !fetching
      ) {
        buttonAddParcelaRef.current.click();
      }
    };

    window.addEventListener('keydown', handleKeyEnterPress);
    return () => window.removeEventListener('keydown', handleKeyEnterPress);
  }, [fetching, finalizacaoContainertRef]);

  const handlePaymentMethodSelection = useCallback(
    async (paymentMethodLabel: string) => {
      if (!initialStatePdv) return;

      setDisabledButtonLabel(paymentMethodLabel);

      const paymentMethodMappings: Record<
        string,
        { tipoFinalizadora: number; tipoOperacaoCartao: number }
      > = {
        dinheiro: { tipoFinalizadora: 0, tipoOperacaoCartao: -1 },
        crédito: { tipoFinalizadora: 1, tipoOperacaoCartao: 0 },
        débito: { tipoFinalizadora: 1, tipoOperacaoCartao: 1 },
        pix: { tipoFinalizadora: 1, tipoOperacaoCartao: 0 },
        'a receber': { tipoFinalizadora: 4, tipoOperacaoCartao: -1 },
      };

      const normalizedLabel = paymentMethodLabel.toLowerCase();
      const selectedPaymentMethod = paymentMethodMappings[normalizedLabel];

      if (selectedPaymentMethod) {
        setFetching(true);

        const { data } = await api.get(
          `/finalizadoras/${initialStatePdv.cod_loja}`,
          {
            params: {
              'finalizadora.tipo_finalizadora':
                selectedPaymentMethod.tipoFinalizadora,
              'finalizadora.tipo_operacao_cartao':
                selectedPaymentMethod.tipoOperacaoCartao,
            },
          },
        );

        if (!data.success || data.data.length === 0) {
          setDisabledButtonLabel(null);
          setFetching(false);
          return toast.warning(
            'Não há uma finalizadora cadastrada com essas características',
          );
        }

        if (data.success) {
          let selectedFinalizadora;

          setTipoFinalizadora(selectedPaymentMethod.tipoFinalizadora);

          if (data.data.length > 1) {
            selectedFinalizadora =
              data.data.find((item: any) =>
                item.des_finalizadora.toLowerCase().includes(normalizedLabel),
              ) || data.data[1];
          } else [selectedFinalizadora] = data.data;

          const { cod_finalizadora, des_finalizadora } = selectedFinalizadora;

          setCodFinalizadora(Number(cod_finalizadora));
          setValue('cod_finalizadora', {
            cod_finalizadora,
            des_finalizadora,
          });

          if (resta > 0) setValue('vlr_parcela', moneyFormat(resta.toFixed(2)));
        }
      }
      await delay(1000);
      setFetching(false);
    },
    [delay, initialStatePdv, resta, setValue],
  );

  return (
    <FormaPagamentoContainer>
      <div className="buttons-finalizadoras">
        {buttonsFinalizadoras.map(({ label, icon: Icon }, idx) => (
          <button
            key={label}
            className="btn-finalizadora"
            type="button"
            name={`forma_finalizadora_${idx}`}
            disabled={fetching || disabledButtonLabel === label}
            onClick={async () => {
              await handlePaymentMethodSelection(label);
            }}
          >
            <Icon color="white" size={20} />
            {label.toUpperCase()}
          </button>
        ))}
      </div>
      <FormaPagamentoContent>
        <InputNumber
          label="Tecla:"
          min={1}
          maxLength={3}
          step="1"
          max={999}
          name="des_tecla"
          placeholder="0"
          register={register}
          control={control}
          isError={!!errors.des_tecla}
          autoComplete="off"
          disabled={isOpenModalFunction || isModalOpen.isOpen || fetching}
          onInput={(ev: ChangeEvent<HTMLInputElement>) => {
            const { value } = ev.target;
            setValue('des_tecla', value);
            setDisabledButtonLabel(null);
          }}
        />
        <SeletorFinalizadora>
          <InputFinalizadora
            name="cod_finalizadora"
            register={register}
            isError={!!errors.cod_finalizadora}
            control={control}
            changeSelected={(selected: any) => {
              const { value, label, tipo_finalizadora } = selected;
              setCodFinalizadora(Number(value));
              setValue('cod_finalizadora', { value, label });
              setDisabledButtonLabel(null);
              setTipoFinalizadora(Number(tipo_finalizadora));
            }}
            disabled={isOpenModalFunction || isModalOpen.isOpen || fetching}
            codLoja={initialStatePdv ? initialStatePdv.cod_loja : 1}
            iniciaRequest={iniciaRequest}
          />
        </SeletorFinalizadora>
        <InputMoney
          label="Valor:"
          placeholder="0,00"
          min={0}
          decimals={2}
          name="vlr_parcela"
          register={register}
          autoComplete="off"
          control={control}
          showIcon={false}
          isError={!!errors.vlr_parcela}
          onInput={(ev: any) => {
            setValue('vlr_parcela', ev.target.value);
          }}
          disabled={isOpenModalFunction || isModalOpen.isOpen || fetching}
          style={{ width: '8.125rem' }}
        />
        <InputNumber
          label={changeLabelQtdParcel ? 'Parcelas:' : 'Qtd Parcelas:'}
          min={1}
          maxLength={2}
          step="1"
          placeholder="1"
          max={99}
          name="qtd_parcelas"
          register={register}
          control={control}
          isError={!!errors.qtd_parcelas}
          autoComplete="off"
          disabled={isOpenModalFunction || isModalOpen.isOpen || fetching}
          onInput={(ev: any) => {
            setValue('qtd_parcelas', ev.target.value);
          }}
          style={{ width: '8.75rem' }}
        />
        <InputDate
          label="Vencimento 1ª Parc."
          name="venc_parcela"
          register={register}
          control={control}
          disabled={isOpenModalFunction || isModalOpen.isOpen || fetching}
          isError={!!errors.venc_parcela}
          onChange={(ev: any) => {
            setValue('venc_parcela', ev.target.value);
          }}
        />
        <ButtonAddFormaPgto
          ref={buttonAddParcelaRef}
          onClick={async () => {
            await handleFormaPagamentoAdd();
          }}
          disabled={disableAdd}
        >
          <BiPlusCircle size={20} />
          Adicionar
        </ButtonAddFormaPgto>
      </FormaPagamentoContent>
      <TableContainer>
        <FormaPagamentoGrid />
      </TableContainer>
      <div style={{ marginTop: '1.25rem' }} />
      <Totalizadores />
    </FormaPagamentoContainer>
  );
};
