import { FaBriefcase, FaMoneyBillAlt } from 'react-icons/fa';
import { FaCreditCard, FaPix } from 'react-icons/fa6';
import { IoIosCard } from 'react-icons/io';

export const buttonsFinalizadoras = [
  { label: 'Dinheiro', icon: FaMoneyBillAlt },
  { label: 'Crédito', icon: FaCreditCard },
  { label: 'Débito', icon: IoIosCard },
  { label: 'Pix', icon: FaPix },
  { label: 'A Receber', icon: FaBriefcase },
];
