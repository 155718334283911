import styled from 'styled-components';

export const FormaPagamentoContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;

  .buttons-finalizadoras {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.9375rem;
    width: 100%;

    .btn-finalizadora {
      border: none;
      background-color: #2b78e4;
      padding: 0.4688rem;
      width: 10rem;
      border-radius: 0.2813rem;
      color: white;
      font-weight: 500;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 0.4375rem;
      }

      &:hover {
        opacity: 90%;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 50%;
      }
    }
  }
`;

export const FormaPagamentoContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  margin-bottom: 0.9375rem;
`;

export const SeletorFinalizadora = styled.div`
  width: 60%;
`;

export const ButtonAddFormaPgto = styled.button`
  border: none;
  background-color: #029d10;
  padding: 0.5625rem;
  width: 12.5rem;
  margin-top: 1.875rem;
  border-radius: 0.4375rem;
  color: white;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-size: medium;
  font-weight: 500;
  text-transform: uppercase;

  svg {
    margin-right: 0.3125rem;
  }

  &:disabled {
    opacity: 60%;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
